@mixin AccordionWidget {
  .accordion-widget-cms {
    .accordion-nav {
      width: 29%;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      @include mobile {
        display: block;
        width: 100%;
      }
      a {
        display: block;
        margin-left: 1.2rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        color: black;
        font-weight: bold;
    }
  }
  
  .accordion-content-wrapper {
      width: auto;
      display: inline-block;
      padding-left: 1rem;
      text-align: left;
      padding: 1em;
      // margin: 10px;
      @include mobile {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      input {
        position: absolute;
        opacity: 0;
        z-index: -1;
        &:checked~.accordion-items-wrapper {
          visibility: hidden;
          opacity: 0;
          max-height: 0;
          margin-top: 0;
        }
        &:checked~.accordion-group-title .gg-arrows-v {
          background: white;
          &::after {
            top: 7px;
            transform: rotate(225deg);
            border-color: black;
          }
        }
        &:checked~.accordion-item-title {
          font-weight: bold;
          border-bottom: 0;
        }
        &:checked~.accordion-item-title .gg-arrows-v {
          background: black;
          &::after {
            top: 9px;
            transform: rotate(46deg);
            border-color: white;
          }
        }
      }
  }
  
  .accordion-content {
    font-size: 14px;
    margin-bottom: 0;
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
      &:checked~.accordion-item-content {
        visibility: visible;
        opacity: 1;
        padding: 15px;
        max-height: 100%;
        padding-left: 0;
        font-size: 16px;
        border-bottom: 1px solid #afb5ba;
      }
    }
  }
  
  .accordion-items-wrapper {
    // visibility: hidden;
    // opacity: 0;
    // transition: all .35s;
    font-size: 14px;
    margin: 0;
    // max-height: 0;
  }
  .accordion-item-content {
    visibility: hidden;
    opacity: 0;
    transition: all .35s;
    font-size: 14px;
    margin: 0;
    max-height: 0;
  }
  
  .accordion-item-title {
      font-size: 16px;
      border-bottom: 1px solid #afb5ba;
      padding: 1.5rem 2.8rem 1.5rem 0;
      position: relative;
      .gg-arrows-v {
        // position: absolute;
        // right: 10px;
        // top: 12px;
        // box-sizing: border-box;
        // display: block;
        // transform: scale(1);
        // width: 10px;
        // height: 20px;
        // background:
        // linear-gradient(
        // to left,
        // currentColor 15px,transparent 0)
        // no-repeat 4px 2px/2px 6px,
        // linear-gradient(
        // to left,
        // currentColor 15px,transparent 0)
        // no-repeat 4px 12px/2px 6px
        position: absolute;
        right: 0;
        top: 15px;
        // padding: 5px;
        // margin: 30px auto;
        background: #fff;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid black;
        }
        .gg-arrows-v::after {
          content: "";
          position: absolute;
          left: 8px;
          z-index: 11;
          display: block;
          width: 5px;
          height: 5px;
          border-top: 2px solid #000;
          border-left: 2px solid #000;
        }
        .gg-arrows-v::after {
          top: 7px;
          transform: rotate(225deg);
        // border-bottom: 2px solid;
        // border-left: 2px solid;
        // bottom: 1px
        }
        // .gg-arrows-v::before {
        // border-top: 2px solid;
        // border-right: 2px solid;
        // top: 1px
        // } 
  }
  .accordion-group-title {
      font-size: 24px;
      font-weight: bold;
      padding-right: 2.8rem;

      .gg-arrows-v {
        position: absolute;
        top: 6px;
        @include mobile {
          right: 0;
          top: 7px;
        }
        background: black;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid black;
        margin-left: 10px;
        }
        .gg-arrows-v::after {
          content: "";
          position: absolute;
          left: 8px;
          z-index: 11;
          display: block;
          width: 5px;
          height: 5px;
          border-top: 2px solid #fff;
          border-left: 2px solid #fff;
        }
        .gg-arrows-v::after {
          top: 9px;
          transform: rotate(46deg);
        }
  }
  &.accordion-only {
    .accordion-content-wrapper {
      display: block;
      width: 100%;
    }
  }
  &.left-nav-both-levels {
    .accordions {
      // display: inline-block;
      // width: 70%;
      @include mobile {
        display: block;
        width: 100%;
      }
      .accordion-content-wrapper {
        display: block;
        width: 100%;
      }
    }
  }
  &.box-navigation {
    .accordion-nav {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @include mobile {
        display: block;
      }
      a {
        font-size: 2.4rem;
      //   font-family: $font-medium;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-style: solid;
        border-color: rgb(52, 52, 52);
        border-width: 0.2rem;
        min-height: 26rem;
        margin: 1rem;
        padding: 1rem;
        /* align-self: center; */
        flex: 1 0 21%;
        max-width: 33.5rem;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .accordion-content-wrapper {
      display: block;
      max-width: var(--content-wrapper-width);
    }
  }
}
}

@mixin ReadMoreWidget {
  .CategoryPage-CMS {
    p {
      &:empty {
        margin-bottom: 0;
      }
    }
    @include mobile {
      padding-top: 0;
    }
  }
  .read-more-widget{
    &.rm-mobile-only {
      .cutoff-text {
        color: var(--paragraph-color);
        margin-bottom: 14px;
        margin-top: 0;
        font-size: var(--paragraph-font-size);
        font-style: var(--paragraph-font-style);
        font-weight: var(--paragraph-font-weight);
        line-height: var(--paragraph-line-height);
        text-transform: var(--paragraph-text-transform);
        @include mobile {
          // --max-lines: 3;
          // --line-height: 1.4;
          // height: calc(var(--max-lines) * 1em * var(--line-height));
          // line-height: var(--line-height);
          // overflow: hidden;
          // position: relative;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          margin-bottom: 0;
        }
      }
      .read-more-expand {
        width: 100%;
        height: 25px;
        margin-top: 5px;
      }
      .gg-arrows-v {
        position: absolute;
        right: 50%;
        top: 0;
        background: #fff;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid black;
        }
        .gg-arrows-v::after {
          content: "";
          position: absolute;
          left: 8px;
          z-index: 11;
          display: block;
          width: 5px;
          height: 5px;
          border-top: 2px solid #000;
          border-left: 2px solid #000;
        }
        .gg-arrows-v::after {
          top: 7px;
          transform: rotate(225deg);
        }
      .cutoff-text:has(+ .expand-btn:checked) {
        // height: auto;
        // overflow: unset;
        -webkit-line-clamp: unset;
        transition: all .35s;
        &::before {
          background: none;
        }
      }
      .expand-btn {
        display: none;
        &:checked~ .read-more-expand .gg-arrows-v {
          background: black;
        &::after {
          top: 9px;
          transform: rotate(46deg);
          border-color: white;
        }
        }
      }
      .expand-btn,
      .read-more-expand {
        @include desktop {
          display: none;
        }
      }
    }
    &.rm-mobile-desktop {
      .cutoff-text {
        --max-lines: 3;
          --line-height: 1.4;
          height: calc(var(--max-lines) * 1em * var(--line-height));
          line-height: var(--line-height);
          overflow: hidden;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            height: calc(2em * var(--line-height));
            width: 100%;
            bottom: 0;
            pointer-events: none;
            background: linear-gradient(to bottom, transparent, #fdf5e8);
          }
      }
      .cutoff-text:has(+ .expand-btn:checked) {
        height: auto;
        overflow: unset;
        transition: all .35s;
        &::before {
          background: none;
        }
      }
      .expand-btn {
        display: none;
      }
    }
  }
}