/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
.CompanyRolesRow {
  margin-bottom:2rem;
  &-Row {
    font-size:1.4rem;
    margin: 1rem 0.5rem;
  }   
  &-RoleCell {
    border:1px solid $default-primary-base-color;
    padding:1.2rem;
    font-size:1.4rem;
    margin:0.5rem;
    cursor: pointer;
    &:hover {
      background-color:#D8D8D8;
    }
  }
}