/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.Menu {
  &-ViewAll {
      flex-basis: 200px;
      @include desktop {
        display:none;
      }
    }
  &-ViewAllLink {
      display: block;
      font-size: 14px;
      color: initial;
      // padding: 1.4rem 0;
      
      &:hover,
      :focus {
        color: var(--menu-item-hover-color);
        text-decoration: none;
      }
  }
  &-MenuWrapper {
      margin-top: 0;
      .Menu-Item {
        &Caption_type_main {
            font-size: 14px;
            font-weight: 400;
            line-height: 61px;
            @media (min-width: 811px)
            and (max-width: 1024px) {
              font-size: 13px;
            }
        }
      }
  }
  &-SubCategories {
    margin-top: 20px;
  }
}
.MenuPage { 
  @include mobile {
    margin-top: 90px;
    margin-bottom: 0;
    .Menu {
      &-MainCategories {
        margin-left: 0;
        .StoreSwitcher {
          border: none;
          .FieldSelect {
            &-Select {
              padding: 5px 10px;
              background-color: #{$default-background};
            }
            .ChevronIcon {
              margin-right: 10px;
            }
          }
        }
      }
      &-CompareLinkWrapper {
        display: none;
      }
      &-ItemList {
        &_type_main {
          padding-bottom: 50px;
        }
        .Menu-Item {
          margin-bottom: 0;
          white-space: nowrap;
          &Caption_type_main {
            line-height: 17px;
            padding-top: 20px;
            padding-bottom: 0;
            margin-right: 24px;
            font-size: 15px;
          }
          &List_type_subcategory {
            padding-top: 12px;
            .Menu-ItemCaption_type_subcategory {
              &.Menu-ItemCaption_isExpanded {
                padding-bottom: 0;
              }
              font-weight: 400;
              padding-top: 0;
              padding-bottom: 12px;
              font-size: 14px;
              margin-right: 8px;              
            }
            .Menu-ViewAll {
              &Link {
                margin-left: 16px;
                font-weight: 400;
                margin-bottom: 10px;
              }
            }
          }          
        }
      }
    }
  }
}