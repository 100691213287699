/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.ImageZoomPopup {
    
    .TranslateOnCursorMove {
        height: calc(((100vw - 150px) / 1.275));
        max-height: calc(100vh - 125px);
    }
    .ProductGallery {
        margin: 0 auto;
        margin-top: 10px;
        height: calc(100% - 40px);
        max-width: 1980px;
        width: calc(100% - 100px);
        &-SliderWrapper {
            padding-top: 0;
        }
        .ProductGallery-Slider_isImageZoomPopupActive {
            .Image-Image {
                transform: initial;
            }
        }
        .ProductGallery-Additional {
            &_isImageZoomPopupActive {
                position: absolute;
                left: 0;
                right: 0;
                bottom: -150px;
                max-width: 602px;
            }
            .CarouselScroll {
                width: auto;
                &Item {
                    min-width: 33.3% !important;
                    //min-width: calc(33.3% - 12px) !important;
                    height: 125px;
                }
            }
        }
    }
}