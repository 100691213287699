/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.Breadcrumbs {
    background-color: #{$default-background};
    padding-top: 10px;
    padding-bottom: 10px;
    @media (min-width: 811px)
        and (max-width: 1439px) {
            padding: 10px 15px;
        }
    &-List {
        padding: 0;
        .Breadcrumb {
            padding-right: 5px;
            margin-bottom: 0;
            &:last-of-type {
                .Breadcrumb-Link {
                    font-weight: bold;
                }
            }
            .ChevronIcon {
                margin-left: 0px;
                padding-left: 5px;
            }
            &-Link {
                text-transform: none;
            }
        }
    }
}