/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
/*
Need to move ASAP...
*/
.HomePage {
    @include mobile {
        margin-top: -16px;
        .CmsPage {
            margin-top: 90px;
        }
    }
    .CmsPage {
        &-Wrapper {
            margin-top: 20px;
        }
        &-Heading {
            margin-bottom: 15px;
            display: none;
        }
        &-Content {
            max-width: $homepage-width;
            margin: 0 auto;
            @include mobile {
                // padding: 0px 15px;
                .home-banner {
                    .Content-ImageBlock {
                        .Background {
                            height: 347px;
                            position: relative;
                            overflow: hidden;
                            img {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: -40%;
                                max-width: unset;
                            }
                        }
                    }
                }
                .Slider-Arrow_isPrev {
                    left: 0;
                }
                .Slider-Arrow_isNext {
                    right: 0;
                }
            }
            div {
                max-width: var(--content-wrapper-width);
            }
        }
    }
}

.CmsPage {
    background:$page-background;
    &-Content {
        background:$page-background;
        .bannerblock-main {
            width: 100%;
            .Content-ImageBlock {
                position: relative;
                .Text {
                    text-align: center;
                    position:absolute;
                    top:45%;
                    left: 50%;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    text-shadow: 2px 2px 3px #000 !important;
                    .TitleWrapper {
                        font-size: 28px;
                        font-family: $font-muli;
                        color: $white;
                    }
                    .Button {
                        background-color: $white;
                        font-size: 20px;
                        border: none;
                        border-radius: 10px;
                        a {
                            color: $black;
                        }
                        @include mobile {
                            max-width: 33%;
                        }
                    }
                }
                .Background {
                    img {
                        max-width: 100%;
                        @include mobile {
                            height: 347px;
                        }
                    }
                }
            }
        }

        .banner-blocks {
            width: 100%;
            display: flex;
            .Content-ImageBlock {
                width: 33%;
                margin-right: 1%;
                position: relative;
                .Text {
                    text-align: center;
                    position:absolute;
                    top:45%;
                    width: 100%;
                    left: 50%;
                     transform: translate(-50%, -50%);

                  .TitleWrapper {
                        font-size: 28px;
                        font-family: $font-muli;
                        color: $white;
                        .Title {
                            text-shadow: 2px 2px 3px #000 !important;
                        }
                    }
                    .Button {
                        background-color: $white;
                        font-size: 20px;
                        border: none;
                        border-radius: 10px;
                        a {
                            color: $black;
                        }
                        @include mobile {
                            max-width: 33%;
                        }
                        @include mid-mobile{
                            max-width: 40%;
                        }
                       @include narrow-mobile{
                           max-width: 50%;
                       }
                    }
                }
                &:last-of-type {
                    margin-right: 0;
                }
                .Background {
                    img {
                        max-width: 100%;
                    }
                }
                @include mobile {
                    width: 100%;
                    display: block;
                    padding-bottom: 15px;
                }
            }
            @include mobile {
                display: block !important;
            }
        }

        .homepage-section-title {
            font-size: 48px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            font-family: $font-pompiere;
            margin-top: 50px;
            p {
                font-size: 48px;
                line-height: 53px;
            }
        }
        .homepage-section-sub-title {
            font-size: 20px;
            width: 100%;
            text-align: center;
            font-family: $font-muli;
            margin-bottom: 15px;
            p {
                font-size: 20px;
            }
        }
        .homepage-product-cards {
            background-color: $white;
            .ProductListPage {
                @include mobile {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            .ProductCard {
                &:hover {
                    box-shadow: none;
                }
                .ProductCard-Link {
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
            &:hover {
                box-shadow: none;
            }
        }
    }
    .HowItWorks {
        margin-left: 20px;
        margin-right: 20px;
        @include mobile {
            margin-left: 0;
            margin-right: 0;
            // page builder important tags required
            .pagebuilder-column-group {
                display: grid !important;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 8px;
                p span {
                    font-size: 18px !important;
                }
                .pagebuilder-column {
                    width: 100% !important;
                    min-width: 25%;
                }
            }
        }
        @include tablet {
            .pagebuilder-column {
                margin-right: 8px;
            }
        }
    }
    .CmsPage-Wrapper {

    }
    h1,h2,h3,h4,h5,h6 {
        text-transform: none;
    }
}

#html-body {
    .CmsPage {
        &-Wrapper {
            @include AccordionWidget;
            @include ReadMoreWidget;
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
            @include mobile {
                padding-left: 15px;
                padding-right: 15px;
                margin-top: calc(var(--header-total-height))
            }
        }
        .CmsPage-Content {

            ul li {
                list-style-type: disc;
                margin-left: 15px;
            }
            ol li {
                list-style-type: numeric;
                margin-left: 15px;
            }
        }
    }
    .HomePage {
        .CmsPage {
            &-Wrapper {
                @include mobile {
                    margin-top: 106px;
                }
            }
        }
    }
}
