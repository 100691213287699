/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
.CompanyUsersRow {
  &-ResultItem {
    // font-size:1.4rem;
    // display: grid;
    // grid-template-columns: 40% 30% 30%;
    // border-bottom: 1px solid var(--expandable-content-divider-color);
    // margin:1rem;
    // padding:1rem;
    // cursor: pointer;
    @include mobile {
      border-bottom: 1px solid var(--expandable-content-divider-color);
      grid-template-columns: repeat(1, 100%);
      padding-bottom: 2rem;
      padding-left: 2rem;
    }
    &_status_0 {
      font-style: italic;
    }
    &:hover {
      background-color:#D8D8D8;
    }
  }
}