/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.DeliveryDateSlot {
  .react-datepicker {
    font-size: 14px;
    font-family: 'muli';
  }
  .react-datepicker__header {
    padding-top: 11px;
  }
  .react-datepicker__month {
    margin: 6px 14px;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 27px;
    line-height: 27px;
    margin: 3px;
  }
  .react-datepicker__current-month {
    font-size: 14px;
    font-family: sans-serif;
  }
  .react-datepicker__navigation {
    top: 7px;
    line-height: 23px;
    width: 23px;
    height: 23px;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #000;
    left: 45px;
  }
  .react-datepicker__navigation--next {
    border-left-color: #000;
    right: 45px;

  }
  .react-datepicker__navigation-icon--previous {
    right: -1px;
    &::before {
      border-color: #000;
      height: 6px;
      top: 8px;
      width: 6px;
    }
  }
  .react-datepicker__navigation-icon--next {
    &::before {
      border-color: #000;
      height: 6px;
      top: 8px;
      width: 6px;
    }
  }
  &-Button {
    width: 50%;
    color: black;
    @include mobile {
      width: 100%;
    }
    .Field {
      text-align: left;
    }
    span {
      font-size: 21px;
      font-weight: 700;
      padding-left: 36px;
      margin-bottom: 10px;
      @include mobile {
        font-size: 18px;
      }
    }
  }
  &-OptionLabel {
    text-align: left;
    padding-left: 30px;
    font-size: 18px;
    @include mobile {
      font-size: 18px;
    }
  }
  .Field [type='checkbox'] + .input-control { 
    border: 1px solid black;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    &:hover {
      &:after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background-color: black;
        border: 1px solid black;
        border-radius: 50%;
        position: absolute;
        left: 4px;
        top: 4px;
        box-shadow: none;
      }
    }
  }
  .Field [type='checkbox']:checked + .input-control::after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background-color: black;
        border: 1px solid black;
        border-radius: 50%;
        position: absolute;
        left: 4px;
        top: 4px;
        box-shadow: none;
   }
  &.FieldForm {
    margin-bottom: 3rem;
  }
}
.DeliveryDateSlot-TimesChoice {
  width: 378px;
  max-width:100%;
  margin-bottom: 24px;
}
.DeliveryDateSlotAlert{
  h2{
    text-transform: none;
  }
}