/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';

.CompanyOverview {
  &-AccountDetails {
    @include desktop {
      // border-right: 1px solid var(--expandable-content-divider-color);
    }
    h3 {
      margin-top: 6px;
    }
    p {
      margin-bottom:0;
    }
  }
  &-EditLink {
    font-size: 1.4rem;
    color: #AFB5BA;
    margin-top: 2rem;
  }
  &-AccountInfo {
    margin-bottom: 20px;
  }
  &-AccountInfoContent {
    display:grid;
    grid-template-columns: 50% 50%;
    @include mobile {
      grid-template-columns: repeat(1, 100%);
      padding-left:2rem;
      border-bottom: 1px solid var(--expandable-content-divider-color);
    }
  }
  &-SubHeading {
    
    // color: #333333;
    // font-size:2.1rem;
    // font-weight: initial;
    // border-bottom: 1px solid #707070;
    // padding-bottom: 1rem;
  
  }
  &-CompanyContact {
    margin-bottom: 30px;
    display:grid;
    grid-template-columns: 50% 50%;
    @include mobile {
      grid-template-columns: repeat(1, 100%);
    }
  }
  &-SingleContact {
    
    h4 {
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 5px;
      // font-size:1.8rem;
      // color: #C7C4C5;
      // font-weight: initial;
      // margin-bottom: 2rem;
    }
    p {
      margin-bottom:0;
    }
  
    @include mobile {
      border-bottom: 1px solid var(--expandable-content-divider-color);
      padding-top:1rem;
      padding-left: 2rem;
      
    }
  }

  &-UserManagement {
    //font-size:1.4rem;
    margin-bottom: 30px;
  }
  &-RoleManagement {
    .Button {
      margin-top:2rem;
    }
  }
  &-Roles {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap
    
  }
}