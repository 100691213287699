/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
.CompanyUserEditForm {
  font-size:1.4rem;
  input[type=text] {
    width:100%;
  }
  .Button {
    margin-top: 2rem;
    width:100%;
  }
  &-DeleteBtn {
    background-color:red;
    color:white;
    padding: 0.5rem;
    margin:0.5rem;
    font-size:1.4rem;
  }
}
