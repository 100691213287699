/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.Footer {
    color: white;
    background: repeating-linear-gradient(
        to right,
        #004479,
        #004479 2px,
        #004c9b 2px,
        #004c9b 4px
    );
    &-Content {
        background: none;
        .ContentWrapper {

            .CmsBlock-Wrapper {
                @include mobile {
                    display: block;
                    width: 100%;
                }
                .pagebuilder-column-group {
                    @include mobile {
                        display: block !important;
                    }
                    @include ultra-narrow-desktop {
                        > div {
                            width: 30% !important;
                            p {
                                margin-bottom: 7px;
                            }
                        }
                        > div:first-of-type {
                            width: 15% !important;
                        }
                        > div:last-of-type {
                            width: 25% !important;
                        }
                    }
                }
                .Footer-Column_isNewsletter {
                    .Footer-ColumnTitle {
                        color: #FFFFFF;
                        max-width: 245px;
                        @include mobile {
                            max-width: none;
                        }
                    }
                }
            }

            .column {
                .column-heading {
                    p {
                        font-size: 20px;
                        color: #FFFFFF;
                        font-weight: 400;
                        text-transform: uppercase;
                        margin-bottom: 14px;
                        line-height: 24px;
                        @include ultra-narrow-desktop {
                            font-size: 14px;
                            line-height: 18px;
                        }
                        @include mobile {
                            padding-top: 25px;
                            padding-bottom: 0px;
                            margin-bottom: 26px;
                        }
                    }
                    
                }
                .column-link {
                    p {
                        a {
                            color: #FFFFFF;
                            font-weight: 400;
                            @include ultra-narrow-desktop {
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                }
                .Footer-ColumnTitle {
                    font-size: 20px;
                    color: #FFFFFF;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin-bottom: 14px;
                    line-height: 24px;
                    @include ultra-narrow-desktop {
                        font-size: 14px;
                        line-height: 18px;
                    }
                    @include mobile {
                        padding-top: 25px;
                        padding-bottom: 0px;
                    }
                }

                @include mobile {
                    width: 100% !important;
                }
            } 
        }
    }
    &-Columns {
        padding-top: 50px;
        padding-bottom: 14px;
        @include mobile {
            padding-top: 25px;
            padding-bottom: 0px;
        }
        &.ContentWrapper {
            .Footer-Column_isNewsletter {
                @include ultra-narrow-desktop {
                    padding-left: 10px;
                    max-width: 210px;
                }
                h3.Footer-ColumnTitle {
                    color: #FFFFFF;
                    max-width: 245px;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 20px;
                    margin-bottom: 14px;
                    @include ultra-narrow-desktop {
                        font-size: 14px;
                        line-height: 18px;
                    }
                    @include mobile {
                        padding-top: 25px;
                        padding-bottom: 0px;
                        max-width: none;
                        text-align: center;
                        margin: 0 auto 15px;
                    }
                }
                .Footer-ColumnContent {
                    .NewsletterSubscription {
                        min-width: auto;
                        @include mobile {
                            margin-bottom: 60px;
                        }
                        .Button {
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin-top: 0;
                            width: auto;
                            background: url("../../../public/images/icon-mail@2x.png") no-repeat;
                            background-color: #1C8000;
                            background-size: 25px;
                            background-position-x: 50%;
                            background-position-y: 50%;
                            width: 46px;
                            text-indent: -9999em; /* hide text with CSS */
                            border: 1px solid #E3E3E3;
                            @include ultra-narrow-desktop {
                                padding-left: 15px;
                                padding-right: 15px;
                                height: 28px;
                                width: 28px;
                                background-size: 20px;
                            }
                        }
                        .FieldForm-Fieldset {
                            .Field {
                                input {
                                    padding: 5px 5px 5px 15px;
                                    min-height: 48px;
                                    margin-bottom: 0;
                                    max-width: 200px;
                                    margin-right: 63px;
                                    color: #9a9a9a;
                                    font-size: 12px;
                                    border: 1px solid #E3E3E3;
                                    border-radius: 1px 0 0 1px;
                                    opacity: 1;
                                    &::placeholder {
                                        color: #C6C6C6;
                                        opacity: 1;
                                    }
                                    &:-ms-input-placeholder {
                                        color: #C6C6C6;
                                        opacity: 1;
                                    }
                                    &:-ms-input-placeholder {
                                        color: #C6C6C6;
                                        opacity: 1;
                                    }
                                    @include ultra-narrow-desktop {
                                        min-height: 28px;
                                        padding: 2px 2px 2px 5px;
                                        color: #4e4e4e;
                                        font-size: 11px;
                                        max-width: 160px;
                                        margin-right: 55px;
                                    }
                                    @include mobile {
                                        margin-right:0
                                    }
                                }
                            }
                        }
                        @include mobile {
                            .FieldForm-Fieldset {
                                max-width: 225px;
                                margin: 0 auto;
                                .Field {
                                    input {
                                        margin-right:41px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        .CmsBlock-Wrapper {
            .Footer-Column {
                padding-left: 25px;
                @include ultra-narrow-desktop {
                    padding-left: 10px;
                }
                @include mobile {
                    padding-left: 0px;
                }
                &Item_type_image {
                    margin: 0 5px 12px 0;
                    width: auto;
                    height: auto;
                    font-size: 0;
                    border: none;
                }
                &Item_type_image:not(:last-of-type) {
                    margin-right: 5px;
                }
            }
        }
    }
    &-CopyrightContent {
        background: #F9E4C5;
        max-width: 100%;
        padding: 0;
    }
    &-Copyright {
        color: $black;
        font-size: 14px;
        font-weight: 400;
    }
}

// Footer Top Banner CSS
.footer-top-banner {
    > .row-full-width-inner {
        width: 100%;
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        @include mobile {
            .pagebuilder-column-group {
                display: block !important;
                .pagebuilder-column {
                    width: 100% !important;
                    margin: 25px 0;
                }
            }
        }
    }
}

.pagebuilder-mobile-hidden {
    @include mobile {
        display: none !important;
    }
}

.pagebuilder-mobile-only {
    @include desktop {
        display: none !important;
    }
}