/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.Field {
    [type='checkbox'] {
        + .input-control {
            border: 1px solid var(--checkbox-border-color-active);
        }
        &:checked {
            + .input-control {
                border: 1px solid var(--checkbox-border-color-active);
            }
        }
    }
}