/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.BackIcon {
    width: 32px;
    height: 36px;
    // fill: var(--color-black);
    cursor: pointer;
    background: url("../../../public/images/back.png") no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 3px;
    margin-left: 10px;;
    span {
        opacity: 0;
    }

    // &_direction {
    //     &_left {
    //         transform: rotate(180deg);

    //         [dir="rtl"] & {
    //             transform: none;
    //         }
    //     }

    //     &_right {
    //         [dir="rtl"] & {
    //             transform: rotate(180deg);
    //         }
    //     }

    //     &_bottom {
    //         transform: rotate(90deg);
    //     }

    //     &_top {
    //         transform: rotate(-90deg);
    //     }
    // }
}