/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';

.PriceList {
    
    &-Container {
        margin-top: 20px;
        position: relative;
        .Button_likeLink {
            font-weight:700;
            &:hover {
                text-decoration: none;
                color: var(--link-hover);
            }
            &:focus {
                text-decoration: none;
            }
        }
    }
    &-TableScroll {
        height: 40vh;
        overflow: auto;
        width: 1050px;
        margin-bottom: 20px;
        @media (max-width: 1375px) {
            width: calc(100vw - 350px);
        }
        @include mobile {
            width: calc(100vw - 30px);
        }
    }
    &-ItemsTable {
        thead {
            position: sticky;
            top: 0;
            z-index: 10;
            th {  
                vertical-align: middle;
                padding: 5px 10px;           
                &.mw100 {
                    min-width: 100px;
                }
                &.mw125 {
                    min-width: 125px;
                }
                &.mw150 {
                    min-width: 150px;
                }
                &.mw175 {
                    min-width: 175px;
                }
                &.mw200 {
                    min-width: 200px;
                }
                &.mw225 {
                    min-width: 225px;
                }
                &.mw250 {
                    min-width: 250px;
                }
            }
        }
        tbody {
            z-index: 5;
        }
    }
}