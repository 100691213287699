/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
.CompanyRolesEditForm {
  font-size:1.4rem;
  
  &-RoleName {
    input {
      width: 100%;
    }
  }
  &-QuotePermissions,
  &-CompanyPermissions,
  &-CompanyUserPermissions {
    margin-left:2rem;
    margin-top:2rem;
  }

  
  
  .Field_type_checkbox {
    display: flex;
    margin:1.2rem 0;
    label {
      display:flex;
      flex-direction: row;
    }
    .input-control {
      margin-right:.5rem;
    }
    span {
      display: block;
    }
  }
  .Button {
    margin: 2rem 0;
    width:100%;
  }
  &-DeleteBtn {
    @include button;
    border-color: #f44336;
    width:100%;
    background-color:red;
    margin-bottom:1rem;
    color: #FFF;
    padding: 1rem;
    font-size:1.4rem;
    &:hover {
      background-color: #F44336;
    }
    &:not([disabled]) {
      &:hover,
      &:focus {
          @include desktop {
            border-color: #f44336;
            background-color: #F44336;
          }
      }
  }
  }
}