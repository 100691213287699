/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.Breadcrumb {
    margin-left: 0px;
    &-Link {
        font-size: 16px;
        font-weight: 400;
        color: $black;
        line-height: 18px;
        .ChevronIcon {
            color: $black;
            filter: unset;
            bottom: 0;
            width: auto;
            
        }
    }
}