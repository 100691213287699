/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
/**
 * Adobe Recommendations compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.RecommendationUnit {
    &-Products {
        display: grid;
        grid-auto-rows: max-content;
        grid-column-gap: 12px;

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(4, 1fr);
        }

        @include wide-desktop {
            grid-template-columns: repeat(6, 1fr);
        }

        @include mobile {
            grid-column-gap: 16px;
            grid-template-columns: 1fr 1fr;
            border: 0;
            padding-inline: 0;
        }
    }
}
