/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.ReCaptcha {
    margin-block-start: 24px;
    z-index: 10;
    display: none;
}

.Popup {
    &_isVisible {
        &~div {
            @include desktop {
                inset-block-start: 20vh !important;
                position: fixed !important;
                z-index: 500;
            }

            @include mobile {
                inset-block-start: var(--header-nav-height) !important;
                inset-inline-start: 0vw !important;
                height: 90vh !important;
                position: fixed !important;
                z-index: 401;
            }

            div:last-of-type {
                @include mobile {
                    min-height: 600px;
                }

                iframe {
                    @include mobile {
                        min-height: 600px;
                    }
                }
            }
        }
    }
}