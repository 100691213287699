/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.HomePage {
    .CmsPage {
        &,
        &-Wrapper {
            // margin-block-end: 0;
            .home-slider {
                .Slider.PageBuilderSlider {
                    .pagebuilder-overlay {
                        width: 450px;
                        position: absolute;
                        right: 35px;
                        max-width: unset;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        opacity: 0.8;
                    }
                    @include desktop {
                        .Slider-Crumbs {
                            background-color: #F9E4C5;
                            margin: 0;
                            padding: 10px 0;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            .Slider-Crumb {
                                width: auto;
                                height: auto;
                                min-width: 150px;
                                padding: 0 20px;
                                background-color: unset;
                                &_isActive {
                                    font-weight: 700;
                                }
                                font-family: 'Muli';
                                font-size: 16px;
                            }
                        }
                    }
                }
                .Slider-Arrow_isPrev {
                    left: 5px;
                }
                .Slider-Arrow_isNext {
                    right: 5px;
                }
                @include mobile {
                    .Slider.PageBuilderSlider {
                        position: relative;
                        .pagebuilder-slide-wrapper {
                            // background-size: auto !important;
                        }
                        .pagebuilder-overlay {
                            width: 100%;
                            position: relative;
                            top: 220px;
                            right: unset;
                            max-width: unset;
                            height: 100%;
                            padding: 10px;
                        }
                        .Slider-Crumbs {
                            display: flex;
                            position: absolute;
                            bottom: auto;
                            top: 45%;
                            .Slider-Crumb {
                                background-color: white;
                                text-indent: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                    }
                    .Slider-Arrow {
                        top:28%;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}
