/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
:root {
    --link-color: var(--imported_link_color, var(--primary-base-color));
    --link-hover: var(--imported_linkHover_color, var(--primary-dark-color));
    --default-hover-link-underline: 2px solid var(--link-hover);
    --link-underline: var(--imported_link_border_bottom, none);
    --link-hover-underline: var(--imported_linkHover_border_bottom, var(--default-hover-link-underline));
}

a {
    border-block-end: var(--link-underline);
    color: var(--link-color);
    font-size: inherit;
    font-weight: 700;

    &:hover {
        color: var(--link-hover);
    }

    &.Link {
        &:hover,
        &:focus {
            border-block-end: var(--link-hover-underline);
            padding-block-end: 3px;
        }
    }
}
