/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/pagebuilder';
.B2BFirstTimeLogin {
    width: 50%;
    margin: 20px auto;

    @include mobile {
        width: 100%;
        margin-top: calc(var(--header-total-height) + 15px);
        margin-left: 10px;
        margin-right: 10px;
    }

    .Form {
        width: 90%;

        input, button {
            width: 100%;
        }

        section {
            display: flex;
            margin-top: 10px;

            a {
                line-height: var(--paragraph-line-height);
                margin-left: 20px;
            }
        }
    }

    &-SignInButton {
        margin-top: 10px;
    }

    &-Recaptcha {
        div {
            width: 100% !important;
            margin-top: 10px;
        }
    }
}